<template>
  <div class="lpr-event-detail">
    <div class="event-detail-content">
      <template>
        <div v-if="!isPlayVideo && !isBackPlay" class="snapshot">
          <EventSnapshot :event="event" />
          <div v-if="videoList.length === total && total > 0" class="icon" @click="handlePlayVideo">
            <img src="@/assets/icons/play-video.svg" alt="play-video" />
          </div>
        </div>
        <div v-else class="video">
          <EventVideo :event="event" />
          <div class="icon close" @click="closeVideo">
            <img src="@/assets/icons/clear.svg" alt="">
          </div>
        </div>
      </template>
      <EventInfoVideo
        v-if="type === 'video'"
        class="event-info"
        :event="event"
      />
      <EventInfoSos
        v-else-if="type === 'sos'"
        class="event-info"
        :event="event"
      />
      <EventInfo v-else class="event-info" :event="event" />
      
    </div>
    <div class="event-detail-map" :class="{ 'screen-shot': isPrintScreen }">
      <template v-if="type === 'video'">
        <EventMap v-if="hasGps" :event="gpsList[0]" type="video" :showVideoPath="true" />
        <img v-else src="@/assets/icons/Map.svg" alt="">
      </template>
      <EventMap v-else :event="event" :type="type" :showVideoPath="true" />
    </div>
  </div>
</template>

<script>
import EventSnapshot from '@/components/Base/EventSnapshot.vue'
import EventVideo from '@/components/Base/EventVideo.vue'
import EventMap from '@/components/Base/EventMap.vue'
import { mapMutations, mapState, mapActions } from 'vuex'
import moment from 'moment'

export default {
  name: 'EventDetailLpr',
  components: {
    EventSnapshot, EventVideo, EventMap,
    EventInfo: () => import('@/components/Base/EventInfo.vue'),
    EventInfoSos: () => import('@/components/Base/EventInfoSos.vue'),
    EventInfoVideo: () => import('@/components/Base/EventInfoVideo.vue'),
  },
  props: {
    event: {
      type: Object
    },
    type: {
      type: String,
      default: 'lpr', // lpr, chased, sos, video
    },
    isPrintScreen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isPlayVideo: false,
    }
  },
  computed: {
    ...mapState('history', ['historyEventModalType']),
    ...mapState('historyVideo', ['videoGpsMap']),
    ...mapState('video', ['total', 'isBackPlay', 'videoList']),
    gpsList() {
      return this.videoGpsMap[this.event.id] || []
    },
    hasGps() {
      return this.gpsList.length > 0
    },
  },
  watch: {
    'event.id'() {
      this.onGetVideoGpsList() // 取得該事件 videoList, gpsList
      this.isPlayVideo = false
      this.updateIsBackPlay(false)
    },
  },
  mounted() {
    if (this.isPrintScreen) return // 截圖時不需要取得影片
    this.onGetVideoGpsList() // 取得該事件 videoList, gpsList  
  },
  beforeDestroy() {
    this.closeVideo()
  },
  methods: {
    ...mapMutations('video', [
      'updateIsBackPlay', 
      'updateVideoIndex',
      'updateVideoList',
      'updateVideoGpsList',
      'updateTotal',
    ]),
    ...mapActions('video', ['getVideoGpsList', 'resetVideoGpsPath']),
    onGetVideoGpsList() {
      this.resetVideoGpsPath()
      // chased = 0 (非追車事件) / chased = 2 (正在追車事件) / chased = 3 (已完成追車事件)
      if (!this.event || (this.type === 'lpr' && this.event.chased === 0)) return

      // 搜尋緊急事件影片時, 以緊急事件的開始/結束時間再 前/後 多三分鐘去搜尋
      const params = {}
      if (this.type === 'sos') {
        params.postId = [this.event.userAccount]
        params.startTime = moment(this.event.startTime).subtract(3, 'minutes').toISOString(),
        params.stopTime = moment(this.event.endTime).add(3, 'minutes').toISOString()
      } else if (this.type === 'chased' || (this.type === 'lpr' && this.event.chased > 0)) { 
        // chased = 2 (正在追車, 沒有chasingEndTime) / chased = 3 (追車事件)
        params.postId = [this.event.user.id]
        params.startTime = moment(this.event.chasingStartTime).subtract(3, 'minutes').toISOString(),
        params.stopTime = this.event.chased === 3
          ? moment(this.event.chasingEndTime).add(3, 'minutes').toISOString()
          : new Date().toISOString()
      } else if (this.type === 'video') {
        // 若是歷史影片，在小卡片時就已經取得 videoList, gpsList，
        // 把小卡片的 video, gpsList 傳到 video/videoList, video/gpsList
        this.updateVideoList([this.event])
        this.updateVideoGpsList(this.videoGpsMap[this.event.id])
        this.updateTotal(1)
        this.updateVideoIndex(0)
      }

      if (Object.keys(params).length === 0 || this.type === 'video') return
      
      this.getVideoGpsList(params)
    },
    handlePlayVideo() {
      this.isPlayVideo = true
      this.updateIsBackPlay(true)
      this.updateVideoIndex(this.videoList.length - 1) // 最舊的影片
    },
    closeVideo() {
      this.isPlayVideo = false
      this.updateIsBackPlay(false)
    },
  },
}
</script>

<style lang="scss" scoped>
.lpr-event-detail {
  width: 100%;
  height: 100%;  
  display: flex;
  column-gap: px2rem(20);
}

.event-detail-content {
  width: 56%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.event-detail-map {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44%;
  height: 100%;
  &.screen-shot {
    height: 600px;
  }

  img {
    width: 30%;
    height: 30%;
    object-fit: contain;
    @include filter_FFF_20;
  }
}

.snapshot, .video {
  position: relative;
  width: 100%;
  height: 58%;
  margin-bottom: 14px;
  .icon {
    position: absolute;
    bottom: 8px;
    right: 8px;
    width: px2rem(48);
    height: px2rem(48);
    background-color: #19191933;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      width: px2rem(28);
      height: px2rem(28);
    }
    &:hover {
      background-color: #19191980;
    }
  }

  .close {
    top: 8px;
    opacity: 0;
    img {
      width: px2rem(20);
      height: px2rem(20);
    }
  } 

  &:hover {
    .close {
      opacity: 1;
      background: #ffffff1a;
    }
  }
}

.event-info {
  width: 100%;
  height: 42%;
  overflow: overlay;
}
</style>