var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"wrapEventVideo",staticClass:"wrap-event-video"},[_c('div',{staticClass:"wrap-video"},[_c('div',{ref:"containerVideo",staticClass:"container-video",on:{"mousedown":_vm.onMouseDown,"mousemove":_vm.onMouseMove,"mouseup":_vm.onMouseUp}},[(!_vm.videoSrc && _vm.videoList.length == 0)?_c('div',{staticClass:"no-stream-info"},[_vm._v(" No video Available ")]):_vm._e(),_c('video',{ref:"video",attrs:{"src":_vm.videoSrc,"autoplay":""},domProps:{"muted":_vm.muted},on:{"loadedmetadata":_vm.onLoadedMetaData,"timeupdate":_vm.onTimeUpdate,"ended":_vm.onVideoEnd}})]),_c('div',{staticClass:"control-panel"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"control-btn",on:{"click":_vm.playOrPause}},[_c('img',{attrs:{"src":_vm.playBtnIcon}})]),_c('div',{staticClass:"control-btn",class:{ muted: _vm.muted },on:{"click":_vm.switchMuted}},[_c('img',{attrs:{"src":_vm.muteIcon}})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.volume),expression:"volume"}],staticClass:"volume",style:(_vm.volumeStyle),attrs:{"type":"range","min":"0","max":"1","step":"0.01"},domProps:{"value":(_vm.volume)},on:{"input":_vm.volumeChange,"__r":function($event){_vm.volume=$event.target.value}}})]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"dropup"},[_c('img',{attrs:{"src":require("@/assets/icons/speedometer.svg")}}),_c('button',{staticClass:"dropbtn",on:{"mouseover":_vm.rateUpMouseOver}},[_vm._v(" "+_vm._s(_vm.playRateLabel)+" ")]),_c('img',{staticClass:"drop",attrs:{"src":require("@/assets/icons/Drop.svg")}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.rateUpActive),expression:"rateUpActive"}],staticClass:"dropup-content"},_vm._l((_vm.playRateList),function(item){return _c('a',{key:item.value,style:([
                item.value == _vm.playRate
                  ? { '--rateup-color': '#0F0' }
                  : { '--rateup-color': '#FFF' }
              ]),on:{"click":function($event){return _vm.selectRate(item.label, item.value)}}},[_vm._v(_vm._s(item.label))])}),0)]),_c('div',{staticClass:"dropup"},[_c('img',{attrs:{"src":require("@/assets/icons/ZoomIn.svg")}}),_c('button',{staticClass:"dropbtn",on:{"mouseover":_vm.handleZoomList}},[_vm._v(" "+_vm._s(_vm.scaleLabel)+" ")]),_c('img',{staticClass:"drop",attrs:{"src":require("@/assets/icons/Drop.svg")}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showZoomList),expression:"showZoomList"}],staticClass:"dropup-content"},_vm._l((_vm.zoomList),function(item){return _c('a',{key:item.value,style:([
                item.value == _vm.scale
                  ? { '--rateup-color': '#0F0' }
                  : { '--rateup-color': '#FFF' }
              ]),on:{"click":function($event){return _vm.selectZoom(item.value)}}},[_vm._v(_vm._s(item.label))])}),0)]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"control-btn show-download",on:{"click":function($event){return _vm.updateIsShowVideoDownloadPanel(true)}}},[_c('img',{attrs:{"src":require("@/assets/icons/download.svg")}})]),_c('div',{staticClass:"control-btn",on:{"click":_vm.extendToFullscreen}},[_c('img',{attrs:{"src":require("@/assets/icons/fullscreen.svg")}})])])])]),_c('transition',{attrs:{"name":"fade"}},[_c('TimeAxis',{ref:"timeAxis",attrs:{"liveList":_vm.liveList,"videoList":_vm.videoList,"videoIndex":_vm.videoIndex,"liveMode":_vm.liveMode,"videoCurrentSeconds":_vm.videoCurrentSeconds,"showArrowTime":false,"reTimeAxis":_vm.reTimeAxis,"permissionV2video":_vm.permissionV2.video,"axisRangeList":_vm.axisRangeList,"axisRange":_vm.timeAxisRange},on:{"update:axisRange":function($event){_vm.timeAxisRange=$event},"update:axis-range":function($event){_vm.timeAxisRange=$event},"stopAndPlayAtSpecificTim":_vm.stopAndPlayAtSpecificTim,"loadVideoWithPointerTim":_vm.loadVideoWithPointerTim,"setReTimeAxis":_vm.setReTimeAxis}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }