<template>
  <div ref="wrapEventVideo" class="wrap-event-video">
    <div class="wrap-video">
      <div ref="containerVideo" class="container-video" @mousedown="onMouseDown" @mousemove="onMouseMove"
      @mouseup="onMouseUp">
        <div v-if="!videoSrc && videoList.length == 0" class="no-stream-info">
          No video Available
        </div>
        <video ref="video" :src="videoSrc" autoplay 
          :muted="muted"
          @loadedmetadata="onLoadedMetaData"
          @timeupdate="onTimeUpdate"
          @ended="onVideoEnd">
        </video>
      </div>
      <div class="control-panel">
        <div class="left">
          <div class="control-btn" @click="playOrPause">
            <img :src="playBtnIcon" />
          </div>
          <div
            class="control-btn"
            :class="{ muted: muted }"
            @click="switchMuted"
          >
            <img :src="muteIcon" />
          </div>
          <input
            type="range"
            min="0"
            max="1"
            class="volume"
            :style="volumeStyle"
            step="0.01"
            v-model="volume"
            @input="volumeChange"
          />
        </div>
        <div class="right">
          <div class="dropup">
            <img src="@/assets/icons/speedometer.svg" />
            <button
              class="dropbtn"
              @mouseover="rateUpMouseOver"
            >
              {{ playRateLabel }}
            </button>
            <img class="drop" src="@/assets/icons/Drop.svg" />
            <div class="dropup-content" v-show="rateUpActive">
              <a
                v-for="item in playRateList"
                :key="item.value"
                :style="[
                  item.value == playRate
                    ? { '--rateup-color': '#0F0' }
                    : { '--rateup-color': '#FFF' }
                ]"
                @click="selectRate(item.label, item.value)"
                >{{ item.label }}</a
              >
            </div>
          </div>
          <div class="dropup">
            <img src="@/assets/icons/ZoomIn.svg" />
            <button
              class="dropbtn"
              @mouseover="handleZoomList"
            >
              {{ scaleLabel }}
            </button>
            <img class="drop" src="@/assets/icons/Drop.svg" />
            <div class="dropup-content" v-show="showZoomList">
              <a
                v-for="item in zoomList"
                :key="item.value"
                :style="[
                  item.value == scale
                    ? { '--rateup-color': '#0F0' }
                    : { '--rateup-color': '#FFF' }
                ]"
                @click="selectZoom(item.value)"
                >{{ item.label }}</a
              >
            </div>
          </div>
          <div class="line"></div>
          <div class="control-btn show-download" @click="updateIsShowVideoDownloadPanel(true)">
            <img src="@/assets/icons/download.svg" />
          </div>
          <div class="control-btn" @click="extendToFullscreen">
            <img src="@/assets/icons/fullscreen.svg" />
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <TimeAxis
        :liveList="liveList"
        :videoList="videoList"
        :videoIndex="videoIndex"
        :liveMode="liveMode"
        :videoCurrentSeconds="videoCurrentSeconds"
        :showArrowTime="false"
        :reTimeAxis="reTimeAxis"
        :permissionV2video="permissionV2.video"
        :axisRangeList="axisRangeList"
        :axisRange.sync="timeAxisRange"
        @stopAndPlayAtSpecificTim="stopAndPlayAtSpecificTim"
        @loadVideoWithPointerTim="loadVideoWithPointerTim"
        @setReTimeAxis="setReTimeAxis"
        ref="timeAxis"
      />
    </transition>
    
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import moment from 'moment'
import TimeAxis from '@/components/TopBar/TimeAxis.vue'

export default {
  name: 'EventVideo',
  props: ['event'],
  components: { TimeAxis },
  data() {
    return {
      liveMode: false,
      videoSrc: null,
      pause: false,
      muted: false,
      volume: 0.5,
      preVolume: 0.5,
      zoomList: [
        { label: '3X', value: 3.0 },
        { label: '2.5X', value: 2.5 },
        { label: '2.25X', value: 2.25 },
        { label: '2X', value: 2.0 },
        { label: '1.75X', value: 1.75 },
        { label: '1.5X', value: 1.5 },
        { label: '1.25X', value: 1.25 },
        { label: '1X', value: 1.0 },
      ],
      fullScreenMode: false,
      playRateList: [
        { label: '16X', value: 16.0 },
        { label: '8X', value: 8.0 },
        { label: '4X', value: 4.0 },
        { label: '2X', value: 2.0 },
        { label: '1X', value: 1.0 },
        { label: '0.5X', value: 0.5 },
        { label: '-1X', value: -1.0 }
      ],
      playRate: 1.0,
      rateUpActive: false,
      duration: '',
      timeElapsed: '',
      videoCurrentSeconds: 20,
      videoDurationSeconds: 100,
      dayList: ['日', '一', '二', '三', '四', '五', '六'],
      videoTime: '',
      /* scale parameters */
      scale: 1.0,
      showZoomList: false,
      panning: false,
      pointX: 0,
      pointY: 0,
      start: { x: 0, y: 0 },
      divWidth: 0,
      divHeight: 0,
      videoWidth: 0,
      videoHeight: 0,
      paddingX: 0,
      paddingY: 0,
      reTimeAxis: false, // 重劃時間軸
      axisRangeList: [
        { label: '2H', value: 1 },
        { label: '1H', value: 0.5 },
        { label: '10M', value: 0.08333333 },
        { label: '5M', value: 0.04166667 },
      ],
      timeAxisRange: 0.08333333,
    }
  },
  computed: {
    ...mapState(['permissionV2', 'liveList']),
    ...mapState('video', ['videoList', 'videoIndex', 'playSpecificTime']),
    ...mapGetters(['timezone']),
    detectDate() {
      return moment(this.event.detectTime).tz(this.timezone).format(
        'YYYY-MM-DD'
      )
    },
    detectDay() {
      return this.dayList[new Date(this.event.detectTime).getDay()]
    },
    playBtnIcon() {
      return this.pause
        ? require('@/assets/icons/play.svg')
        : require('@/assets/icons/pause.svg')
    },
    muteIcon() {
      return this.muted
        ? require('@/assets/icons/volume-mute.svg')
        : require('@/assets/icons/volume-2.svg')
    },
    timebarStyle() {
      return {
        '--timebar': this.videoCurrentSeconds / this.videoDurationSeconds * 100 + '%'
      }
    },
    volumeStyle() {
      return {
        '--volume': this.volume * 100 + '%'
      }
    },
    playRateLabel() {
      return this.playRateList.find(item => item.value === this.playRate).label
    },
    scaleLabel() {
      return this.zoomList.find(item => item.value === this.scale).label
    },
  },
  watch: {
    'event.id'() {
      // this.timeAxisRange = this.getTimeAxisRange() // 取得時間軸範圍
      this.playVideo()
    },
    playSpecificTime() {
      if(this.playSpecificTime) {
        this.stopAndPlayAtSpecificTim(this.playSpecificTime)
      }
    },
  },
  mounted() {
    // this.timeAxisRange = this.getTimeAxisRange() // 取得時間軸範圍
    this.playVideo()
  },
  methods: {
    ...mapMutations('video', ['updateVideoTime', 'updateVideoIndex', 'updateIsShowVideoDownloadPanel']),
    ...mapActions(['getVideoGpsList', 'resetVideoGpsMarkerPath']),
    getTimeAxisRange() {
      if (this.videoList.length === 0) return 0.04166667
      const start = new Date(this.videoList[this.videoList.length - 1].startTime).getTime()
      const end = new Date(this.videoList[0].stopTime).getTime()
      const diff = (end - start) / 60000 // 分鐘
      let range = 0.04166667
      if (diff > 5) range = 0.08333333
      else if (diff > 10) range = 0.5
      else if (diff > 60) range = 1

      return range
    },
    playOrPause() {
      this.pause = !this.pause
      if (this.pause) {
        this.$refs.video.pause()
      } else {
        this.$refs.video.play()
      }
    },
    extendToFullscreen() {
      if (!this.fullScreenMode) {
        this.$refs.wrapEventVideo.webkitRequestFullScreen()
      } else {
        document.webkitExitFullscreen()
      }
      this.fullScreenMode = !this.fullScreenMode
    },
    switchMuted() {
      this.muted = !this.muted
      if (this.muted) {
        this.preVolume = this.volume
        this.volume = 0
      } else {
        this.volume = this.preVolume
      }
      this.$refs.video.volume = this.volume
    },
    volumeChange() {
      this.$refs.video.volume = this.volume
    },
    rateUpMouseOver() {
      this.rateUpActive = true
    },
    handleZoomList() {
      this.showZoomList = true
    },
    selectRate(label, value) {
      this.playRate = value
      this.rateUpActive = !this.rateUpActive
      // document.getElementsByClassName('dropup-content')[0].style.display = 'none'
      if (value < 0) {
        this.rewind(1.0)
      } else {
        if (this.intervalRewind) clearInterval(this.intervalRewind)
        this.$refs.video.playbackRate = value
        this.pause = false
        this.$refs.video.play()
      }
    }, 
    selectZoom(value) {
      this.showZoomList = false
      this.scale = value
      if (this.scale == 1.0) this.resetZoomParams()
      else {
        this.correctTranslatePoint()
        this.setTransform()
      }
    },
    // 倒回播放功能
    rewind(rewindSpeed) {
      if (this.intervalRewind) clearInterval(this.intervalRewind)
      let video = this.$refs.video
      let startSystemTime = new Date().getTime()
      let startVideoTime = video.currentTime
      this.intervalRewind = setInterval(() => {
        video.playbackRate = 1.0
        if (video.currentTime < 0.1) {
          clearInterval(this.intervalRewind)
          video.pause()
          this.pause = true
          this.playRate = 1.0
        } else {
          let elapsed = new Date().getTime() - startSystemTime
          // log.textContent='Rewind Elapsed: '+elapsed.toFixed(3)
          video.currentTime = Math.max(
            startVideoTime - (elapsed * rewindSpeed) / 1000.0,
            0
          )
        }
      }, 30)
    },
    onLoadedMetaData() {
      // this.loading = false
      const videoDuration = Math.round(this.$refs.video.duration)
      this.duration = this.formatTime(videoDuration)
      this.getVideoParams()
    },
    onTimeUpdate() {
      if (!this.$refs.video) return
      this.videoCurrentSeconds = this.$refs.video.currentTime
      this.timeElapsed = this.formatTime(this.videoCurrentSeconds)
      this.pause = false

      if (
        this.timeElapsed.minutes == this.duration.minutes &&
        this.timeElapsed.seconds == this.duration.seconds
      ) {
        this.pause = true
      }
    },
    formatTime(timeInSeconds) {
      const result = new Date(timeInSeconds * 1000).toISOString().substr(11, 8)
      return {
        minutes: result.substr(3, 2),
        seconds: result.substr(6, 2)
      }
    },
    playVideo(secDiff=null) {
      if (this.pause === true) this.$refs.video.play

      this.videoSrc = this.videoList[this.videoIndex].videoUrl
      this.$refs.video.playbackRate = this.playRate
      if(secDiff) { this.$refs.video.currentTime = secDiff }
    },
    onVideoEnd() {
      // videoIndex 越小, 影片越新
      if (this.videoIndex > 0) {
        this.updateVideoIndex(this.videoIndex - 1)
        this.playVideo()
      }
    },
    stopAndPlayAtSpecificTim(tim) {
      if(this.specificTimeout) { clearTimeout(this.specificTimeout) }
      // this.stopLiveVideo()
      this.specificTimeout = setTimeout(() => { 
        this.getSpecificTimVideo(tim) 
      }, 200)
    },
    loadVideoWithPointerTim(pointerTim) {
      // 更新時間
      this.videoTime = moment(pointerTim).tz(this.timezone).format('HH:mm:ss')
      // 播放歷史影片時, 根據影片時間同步更新marker & path
      // 當影片播放時，再進行更新，否則在影片切換時pointerTim會突然跳到該段video片尾時間（不確定原因）
      if (this.$refs.video.currentTime > 0) {
        this.updateVideoTime(pointerTim.toISOString())
      } 
    },
    getSpecificTimVideo(tim) {
      // 用現有的videoList找到index 播放影片後
      // 以pointerTim往前後2.5小時撈資料(大部分約100筆) 更新videoList和videoIndex
      let index = this.assignVideoIndex(tim)
      if (index >= 0) { 
        this.playSpecificVideo(tim, index)
      }
    },
    assignVideoIndex(tim) {
      // 找到符合時間的影片 並以此index更新videoIndex
      let index = this.videoList.findIndex(item => {
        let s = new Date(this.utcToTaipeiTime(item.startTime))
        let e = new Date(this.utcToTaipeiTime(item.stopTime))
        return ((+tim >= s) && (+tim < +e))
      })
      return index
    },
    playSpecificVideo(tim, index) {
      // 播放特定時間的歷史影片
      this.updateVideoIndex(index)

      // 先清除videoPath之後重劃
      this.$store.commit('clearVideoPath')
      if(index < 0) return
      let s = new Date(this.utcToTaipeiTime(this.videoList[this.videoIndex].startTime))
      let secDiff = moment(tim).diff(s) / 1000
      this.playVideo(secDiff)
      this.$refs.timeAxis.manualMoveTim = false
    },
    utcToTaipeiTime(tim) {
      // 轉換成UI畫面上的時間
      return moment(tim).tz(this.timezone).format('YYYY/MM/DD HH:mm:ss')
    },
    setReTimeAxis(bool) {
      this.reTimeAxis = bool
    },
    getVideoParams() {
      // 計算影像zoomImg維持原長寬比例，顯示於zoom中，x,y 方向的padding
      this.divWidth = this.$refs.containerVideo.offsetWidth
      this.divHeight = this.$refs.containerVideo.offsetHeight
      let videoNaturalWidth = this.$refs.video.videoWidth
      let videoNaturalHeight = this.$refs.video.videoHeight
      let fitScale = Math.min(this.divWidth / videoNaturalWidth, this.divHeight / videoNaturalHeight)
      this.videoWidth = videoNaturalWidth * fitScale
      this.videoHeight = videoNaturalHeight * fitScale
      this.paddingX = (this.divWidth - this.videoWidth) / 2
      this.paddingY = (this.divHeight - this.videoHeight) / 2
    },
    resetZoomParams() {
      this.pointX = 0
      this.pointY = 0
      this.start = { x: 0, y: 0 }
      this.scale = 1.0
      this.$refs.containerVideo.style.transform = `scale(${this.scale})`
    },
    correctTranslatePoint() {
      let paddingLeft = -this.paddingX * this.scale
      let paddingRight = -(this.videoWidth * this.scale - paddingLeft - this.divWidth)
      let paddingTop = -this.paddingY * this.scale
      let paddingBottom = -(this.videoHeight * this.scale - paddingTop - this.divHeight)

      if (this.pointX > paddingLeft) this.pointX = paddingLeft
      if (this.pointX < paddingRight) this.pointX = paddingRight
      if (this.pointY > paddingTop) this.pointY = paddingTop
      if (this.pointY < paddingBottom) this.pointY = paddingBottom
    },
    setTransform() {
      this.$refs.containerVideo.style.transform = `translate(${this.pointX}px, ${this.pointY}px) scale(${this.scale})`
    },
    onWheel(e) {
      e.preventDefault()
      let xs = (e.clientX - this.pointX) / this.scale
      let ys = (e.clientY - this.pointY) / this.scale
      let delta = (e.wheelDelta ? e.wheelDelta : -e.deltaY)
      if (delta > 0) { // 放大
        this.scale *= 1.05
      } else { // 縮小，最小倍率為1.0
        let tmpScale = this.scale / 1.05
        this.scale = tmpScale < 1.0 ? 1.0 : tmpScale
      }

      this.pointX = e.clientX - xs * this.scale
      this.pointY = e.clientY - ys * this.scale
      
      if (delta <= 0) this.correctTranslatePoint()
      if (this.scale == 1.0) this.resetZoomParams()

      this.setTransform()
    },
    onMouseDown(e) {
      e.preventDefault()
      this.start = { x: e.clientX - this.pointX, y: e.clientY - this.pointY }
      this.panning = true
    },
    onMouseMove(e) {
      e.preventDefault()
      if (!this.panning || this.scale == 1.0) return
      this.pointX = e.clientX - this.start.x
      this.pointY = e.clientY - this.start.y
      this.correctTranslatePoint()
      this.setTransform()
    },
    onMouseUp() {
      this.panning = false
    },
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.wrap-event-video {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.wrap-video {
  width: 100%;
  height: calc(100% - 80px);
  overflow: hidden;
  border: 1px solid #4A5C7888; 
  position: relative;
  margin-bottom: 7px;
}

.container-video {
  width: 100%;
  height: 100%;
  transform-origin: 0px 0px;
  transform: scale(1) translate(0px, 0px);
}

.no-stream-info {
  width: 100%;
  height: 100%;
  background-color: #010101;
  color: #666666;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-video video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.control-panel {
  position: absolute;
  width: 100%;
  height: 40px;
  bottom: 0;
  left: 0;
  padding-left: 12px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(25, 25, 25, 0.4);
  opacity: 0;
}

.wrap-video:hover .control-panel {
  padding: 0 28px;
  opacity: 1;
  transition: opacity 0.5s;
}

.left, .right {
  display: flex;
  align-items: center;
  column-gap: 16px;
}

.line {
  width: 1px;
  height: 24px;
  background-color: #ffffff;
  opacity: 0.5;
}

.control-btn {
  width: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.muted {
  width: 20px;
  margin-right: 4px;
}

img {
  width: 100%;
  height: 100%;
}

.volume {
  -webkit-appearance: none;
  width: px2rem(80);
  height: 0.5rem;
  margin-left: 12px;
  position: relative;
  left: -10px;
  border-radius: 5px;
  background-image: linear-gradient(
    to right,
    #ffcd16,
    #FFC600 var(--volume),
    #999696 var(--volume),
    #999696 100%
  );
}

.volume::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.1s;
  background-color: rgba(255, 255, 255);
  position: relative;
}

.dropbtn {
  background-color: transparent;
  color: var(--dropbtn-color);
  min-width: 56px;
  height: 40px;
  font-size: px2rem(20);
  border: none;
  cursor: pointer;
}

.dropup {
  position: relative;
  display: flex;
  align-items: center;

  img:first-child {
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }

  img.drop {
    width: 16px;
    height: 16px;
  }
}

.dropup-content {
  display: none;
  position: absolute;
  font-size: 18px;
  color: var(--rateup-color);
  background-color: rgba(25, 25, 25, 0.4);
  min-width: 56px;
  left: 28px;
  bottom: 40px;
  z-index: 1;
}

.dropup-content a {
  font-size: 18px;
  color: var(--rateup-color);
  padding: 3px 6px;
  text-decoration: none;
  text-align: center;
  display: block;
  cursor: pointer;
}

.dropup-content a:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.dropup:hover {
  .dropup-content {
    display: block;
  }
  .dropbtn {
    background-color: rgba(255, 255, 255, 0.2);
  }
  img.drop {
    transform: rotate(180deg);
    transition: all 250ms;
  }
}

.time span {
  font-weight: 300;
  color: #ffffff;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to, .fade-leave-active /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@media screen and (max-width: 1440px) {
  .date, .time {
    font-size: 18px;
  }

  .wrap-video {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 840px) {
  .date, .time {
    font-size: 16px;
  }
}
</style>